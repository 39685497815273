import './style.css'
import '../../index.css'

function Challenge(){
  return (
    <div className="challenge-container">
      <div className="main-container">
          <div className="challenge-content">
            <img 
              src="/assets/images/crypto.svg" 
              className="challenge-img" 
              alt="challenge-img"
            />
            <div className="challenge-txt">
              <h2>Simplify your community monetization</h2>
              <h3>For online communities on platforms like Telegram and Discord, monetization can be a complex process.</h3>
              <p>AdBash simplifies this journey by serving as a comprehensive platform that brings advertisers and communities together. We enable communities to explore advertising options, generate revenue, and maximize their online potential.</p>
            </div>
          </div>

          <div className="challenge-content alt-challenge">
            <img 
              src="/assets/images/course.svg" 
              className="course-img" 
              alt="course-img"
            />
            
            <div className="challenge-txt">
              <h2>AdBash is the first AdPlatform tailor-built for online communities.</h2>
              <h3>We aggregate the biggest and most effective Telegram and Discord communities, and provide real-time tracking for both communities and advertisers.</h3>
              <p>The AdBash team reviews every single ad on the network, as well as thoroughly examines each community before it is on-boarded to ensure the best quality products are advertised in your communities. </p>
            </div>
          </div>
      </div>
    </div>
  )
}

export default Challenge