import './style.css';
import '../../index.css'

function Introduction() {
  return (
    <div className="main-container">
      <div className="intro-container">
        <div className="intro-txt">
          <h1>
            <span>The leading Ad Network</span><br/>tailor-built for online communities
          </h1>

          <img 
            src="/assets/images/product.svg" 
            className="intr-pic small-pic"
          />

          <div className="intro-img-txt">
            <div className="img-txt">
              <div className="img-container">
                <img src="/assets/images/lighthouse.svg"/>
              </div>
              <p>Expose your business to leading communities in the digital space.</p>
            </div>

            <div className="img-txt">
              <div className="img-container">
                <img src="/assets/images/funnel.svg"/>
              </div>
              <p>Monetize your Telegram and Discord communities effortlessly with our automated Advertising platform.</p>
            </div>
          </div>
          <a href="https://app.adbash.io"><button>Start Now</button></a>
        </div>
        
        <img 
          src="/assets/images/product.svg" 
          className="intr-pic"
        />
      </div>
    </div>
  )
}

export default Introduction;