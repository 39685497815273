import './style.css';
import '../../index.css'

function Header(){
  return (
    <div className="header-container">
      <div className="main-container">
        <div className="header-content">
          <img 
            src="/assets/images/logo.svg" 
            className="main-logo" 
            alt="logo-img"
          />
          <div className="nav-items">
            <ul> 
              <li>
                <a
                  target="_blank"
                  href="https://t.me/adbashio"
                >
                  <img src="/assets/images/telegram.svg"/>
                </a>
              </li>
            </ul>
            <a href="https://app.adbash.io"><button>Start Now</button></a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;