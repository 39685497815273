import './style.css'
import '../../index.css'

function Subscription(){
  return (
    <div className="sub-main-container">
      <div className="subs-container">
        <div className="subs-details">
          <img src="/assets/images/blog.svg" className="blog-img" alt="blog-img"/>
          <h2>Broadcast your project to the best targeted communities on Telegram and Discord</h2>
          <p>Get maximum coverage for your products.</p>
        </div>

        <div className="subscriptions-container">
          <div className="sub-row">
            <div className="subscriptions">
              <div className="sub-head">
                <h2>Starter</h2>
                <span>$100</span>
              </div>
              <div className="sub-inner">
                <h3>25,000 Views</h3>
                <p>Our standard package perfect to try out our system</p>
                <a href="https://app.adbash.io">
                  <button>Start Now</button>
                </a>
              </div>
            </div>
            <div className="subscriptions subscription-silver">
              <div className="sub-head">
                <h2>Silver</h2>
                <span>$500</span>
              </div>
              <div className="sub-inner">
                <h3>145,000 Views</h3>
                <p>Take your advertising to the next level and pay <b>16% less</b> for your views</p>
                <a href="https://app.adbash.io">
                  <button>Start Now</button>
                </a>
              </div>
            </div>
          </div>
          <div className="subscriptions subscription-gold">
            <div className="sub-head">
              <h2>Gold</h2>
              <span>$2,000</span>
            </div>
            <div className="sub-inner">
              <div className="inner-left">
                <h3>600,000 Views</h3>
                <p>Take your advertising to the next level <br/>and pay <b>20% less</b> for your views</p>
              </div>
              <a href="https://app.adbash.io">
                <button>Start Now</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Subscription