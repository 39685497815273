import './style.css';
import '../../index.css'

function Title(){
    return(
      <div className="title-container">
        <div className="main-container">
          <h2>Unleash Your Community's Potential and Amplify Your Ad Campaigns</h2>
          <a href="https://app.adbash.io"><button>Start Now</button></a>
        </div>
      </div>
    )
}

export default Title;