import './style.css';
import '../../index.css'

function Faq(){
  return (
    <div className="faq-container">
      <div className="main-container">
        <div className="faq-content">
          <div className="faq-title">
            <h2>Frequently asked questions</h2>
          </div>

          <div className="faqs">
            <div className="accordion" id="faq-accordion">
              <FaqQuestion
                question="What is AdBash?"
                answer="AdBash is an innovative ad network that specializes in connecting ad buyers with thriving online communities on platforms such as Telegram and Discord. By automating the monetization process and facilitating efficient ad placements, AdBash creates a win-win situation for both community owners and advertisers."
              />
              <FaqQuestion
                question="How does AdBash stand out from traditional Ad networks?"
                answer="AdBash is dedicated to empowering online communities on Telegram and Discord. It stands as the first company that facilitates collaboration between ad buyers and these communities, enabling the monetization of community spaces through targeted ads. Additionally, AdBash provides real-time tracking for advertisers to measure campaign effectiveness and ensures high standards through rigorous ad reviews."
              />
              <FaqQuestion
                question="How does AdBash ensure quality and performance?"
                answer="AdBash ensures quality through experienced marketeer reviews and continuous analysis of community performance on our network."
              />
              <FaqQuestion
                question="How can I monetize my community with AdBash?"
                answer="With AdBash, you can monetize your community by accepting ads. We place your ads in the best-performing communities, maximizing revenue. Instant top-ups and crypto payouts are available."
              />
              <FaqQuestion
                question="What tracking and statistics does AdBash provide?"
                answer="AdBash offers real-time tracking with detailed impressions, clicks, and other relevant statistics for optimizing your marketing strategy."
              />
              <FaqQuestion
                question="How does AdBash optimize my ad budget?"
                answer="AdBash optimizes your ad budget by placing ads in high-performing communities, ensuring efficient spending and maximizing ROI."
              />
              <FaqQuestion
                question="Can I trust the quality of ads on AdBash?"
                answer="Absolutely! AdBash reviews every ad and thoroughly examines communities and advertisers to maintain high quality and trustworthiness."
              />
              <FaqQuestion
                question="How much can I earn from AdBash?"
                answer="Earnings on AdBash are influenced by various factors including community size and engagement. AdBash employs sophisticated AI technology to analyze these elements, which in turn assigns a quality score to each community. This quality score is instrumental in determining the cost for ads on each page. Therefore, earnings can vary based on the quality and activity of your community."
              />
            </div>
          </div>
        </div>
      </div>
      <img src="/assets/images/discovery.svg" className="disc-img" alt="disc-img"/>
    </div>
  )
}

function FaqQuestion({ question, answer }) {
  const id = Math.random().toString(36).slice(-6)
  const panelId = `panelsStayOpen-collapse-${id}`

  return (
    <div className="accordion-item">
      <h2 
        className="accordion-header"
        id={`header-${id}`}
      >
        <button 
          className="accordion-button collapsed" 
          type="button" 
          data-bs-toggle="collapse" 
          data-bs-target={`#${panelId}`}
          aria-expanded="false" 
          aria-controls={panelId}
        >
          { question }
        </button>
      </h2>
      <div 
        id={panelId}
        className="accordion-collapse collapse" 
      >
        <div className="accordion-body">
          <p className='dropped'>
            { answer }
          </p>
        </div>
      </div>
    </div>
  )
}

export default Faq;