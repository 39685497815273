import './style.css';
import '../../index.css'

function Services(){
  return (
    <div className="main-container">
      <div className="services-container">
        <div className="service-row">
          <div className="service">
            <img src="/assets/images/monetize.svg" className="service-img" alt="service-img"/>
            <div className="service-content">
              <h2>Monetize your community your way</h2>
              <p>AdBash will ensure that your ads are posted to the best performing communities so that your ad budget is always spent efficiently.</p>
            </div>
          </div>

          <div className="service">
            <img src="/assets/images/instant.svg" className="service-img" alt="service-img"/>
            <div className="service-content">
              <h2>Instant top-ups and pay-outs through Crypto</h2>
              <p>Top up your advertising account with impressions easily through the platform, and cash out your earnings directly from our dashboard to your wallet.</p>
            </div>
          </div>
        </div>

        <div className="service-row">
          <div className="service">
            <img src="/assets/images/tracking.svg" className="service-img" alt="service-img"/>
            <div className="service-content">
              <h2>Real-time tracking</h2>
              <p>We provide detailed information of ads posted, in real-time. This includes the number of impressions, clicks, and more statistics needed for you to optimize your marketing strategy.</p>
            </div>
          </div>

          <div className="service">
            <img src="/assets/images/optimize.svg" className="service-img" alt="service-img"/>
            <div className="service-content">
              <h2>Automated Optimization</h2>
              <p>AdBash will ensure that your ads are posted to the best performing communities so that your ad budget is always spent efficiently.</p>
            </div>
          </div>
        </div>

        <div className="service-row">
          <div className="service">
            <img src="/assets/images/qa.svg" className="service-img" alt="service-img"/>
            <div className="service-content">
              <h2>Quality Assurance</h2>
              <p>Our team of experienced marketeers review every community onboarded thoroughly, as well as every advertiser, to ensure extreme quality of the network.</p>
            </div>
          </div>

          <div className="service">
            <img src="/assets/images/tracking.svg" className="service-img" alt="service-img"/>
            <div className="service-content">
              <h2>Real-time tracking</h2>
              <p>We provide detailed information of ads posted, in real-time. This includes the number of impressions, clicks, and more statistics needed for you to optimize your marketing strategy.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services;